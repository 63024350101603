<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									OTOMASYON PROJESİ SATIŞ
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0" v-if="$router.options.history.state.back != null" icon="pi pi-times" @click="$router.go(-1)"/>
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<EntityHeader label="OTOMASYON PROJESİ SATIŞ" :entityName="ENTITY_NAME"></EntityHeader>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bm_satistemsilcisiId" v-model="mainData.bm_satistemsilcisiIdName" ref="entity_bm_satistemsilcisiId"
									label="Satış Temsilcisi" entityName="bm_teknikkullanicilar" nameField="bm_name" :state="true" :required="true" :disabled="isDevreDisi"
									@itemSelected="bm_satistemsilcisiSelected = $event" @itemCleared="bm_satistemsilcisiSelected = null">
								</EntityLookup>
							</div>
						</div>
						
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_ilerlemedurumu">Ödeme İlerleme Durumu</label>
								<Dropdown id="bm_ilerlemedurumu" v-model="mainData.bm_ilerlemedurumu" :options="SM_bm_ilerlemedurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="İlerleme Durumu" :showClear="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="odemeTutari">Tutar <span style="color:red">*</span></label>
								<InputNumber id="odemeTutari" v-model="mainData.bm_fiyat" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_fiyat.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_fiyat.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="bm_parabirimi" v-model="mainData.bm_parabirimiName" ref="entity_bm_parabirimi"
									label="Para Birimi" entityName="transactioncurrency" nameField="isocurrencycode" :state="true" :required="true" :disabled="isDevreDisi"
									@itemSelected="bm_parabirimiSelected = $event" @itemCleared="bm_parabirimiSelected = null">
								</EntityLookup>
								<span v-if="v$.mainData.bm_parabirimiId.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_parabirimiId.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						
						
						<div class="col-12">
							<div class="field p-fluid">
								<label for="ozelnot">Özel Not</label>
								<Textarea id="ozelnot" rows="4" :autoResize="false" v-model="mainData.bm_ozelnot" :disabled="isDevreDisi" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="grid">
			<div class="col-12">
				<div class="card">
					<h5>TARİH BİLGİLERİ (ÖDEME)</h5>
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_tarih">Sözleşme Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_tarih" v-model="mainData.bm_tarih" autocomplete="off" :showIcon="true" :disabled="isDevreDisi"/>
								<span v-if="v$.mainData.bm_tarih.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_tarih.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-1">
							<div class="field p-fluid">
								<label for="sureHafta">Süre (Hafta)</label>
								<InputNumber id="sureHafta" v-model="mainData.bm_surehafta" mode="decimal" locale="tr-TR" :minFractionDigits="0" showButtons :min="0" :max="520" :disabled="isDevreDisi" @input="changeSureHafta($event.value)" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_termintarihi">Son Ödeme Tarihi <span style="color:red">*</span></label>
								<Calendar showButtonBar id="bm_termintarihi" v-model="mainData.bm_termintarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
								<span v-if="v$.mainData.bm_termintarihi.$error && submitted">
									<span id="email-error" v-for="(error, index) of v$.mainData.bm_termintarihi.$errors" :key="index">
										<small class="p-error">{{error.$message}}</small>
									</span>
								</span>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="bm_tamamlanmatarihi">Ödeme Tamamlanma Tarihi</label>
								<Calendar showButtonBar id="bm_tamamlanmatarihi" v-model="mainData.bm_tamamlanmatarihi" autocomplete="off" :showIcon="true" :disabled="isDevreDisi" />
							</div>
						</div>
						<div class="col-2">
							<div class="field p-fluid">
								<label for="bm_xgunoncehatirlat">x Gün Önce Hatırlat</label>
								<Dropdown id="bm_xgunoncehatirlat" v-model="mainData.bm_xgunoncehatirlat" :options="SM_bm_xgunoncehatirlat" optionLabel="Value" optionValue="AttributeValue" placeholder="x gün Önce Hatırlat" :showClear="true" :disabled="isDevreDisi" />
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10052,
			ENTITY_NAME: 'bm_otomasyonsatis',
			ENTITY_SUBJECT: 'Otomasyon Projesi',
			mainData: {},

			nestedMenuitems: [
				{
					label:'Kaydet',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label:'Kaydet & Kapat',
					icon:'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
					visible: () => this.isDeleted
                },
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-check-circle',
					visible: () => !this.isDevreDisi,
					command: () => { this.OnDevreDisiBirak(); },
				},
				{
					label: 'Yeniden ETKİNLEŞTİR',
					icon: 'pi pi-fw pi-history',
					visible: () => this.isDevreDisi,
					command: () => { this.OnYenidenEtkinlestirItem(); },
                },
			],
		}
	},
	async created() {
		this.crmService = new CrmService();
		console.log('created');

		window.addEventListener('scroll', this.handleScroll);

		this.OnLoad();
    },
	computed: {
		entityId() {
			debugger;
			return this.$route.params.id;
		},
		otomasyonProjesiId() {
			debugger;
			return this.$route.params.id;
		},
		profileData(){
			return this.$store.getters.getProfile;
		},
		isDevreDisi: function() {
			if (this.isYazmaYetkisiVarmi == false) {
				return true;
			}

			if (this.mainData["statecode"]) {
				if (this.mainData["statecode"] == 1) {
					return true;
				}
			}

			return false;
		},
		isYazmaYetkisiVarmi: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == true;
					}
				}
			}

			return false;
		},
		isDeleted: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Otomasyon Projesi');
					if (filtered.length > 0) {
						return filtered[0].isDelete == true;
					}
				}
			}

			return false;
		},
		SM_bm_ilerlemedurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ilerlemedurumu');
		},
		SM_bm_xgunoncehatirlat: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_xgunoncehatirlat');
		},
		bm_satistemsilcisiSelected: {
			get: function () {
				if (this.mainData["bm_satistemsilcisiId"]) {
					return { "Value": this.mainData["bm_satistemsilcisiId"], "Name": this.mainData["bm_satistemsilcisiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_satistemsilcisiId"] = null;
					this.mainData["bm_satistemsilcisiName"] = null;
				}
				else {
					this.mainData["bm_satistemsilcisiId"] = newValue.Value;
					this.mainData["bm_satistemsilcisiName"] = newValue.Name;
				}
			}
		},
		bm_parabirimiSelected: {
			get: function () {
				if (this.mainData["bm_parabirimiId"]) {
					return { "Value": this.mainData["bm_parabirimiId"], "Name": this.mainData["bm_parabirimiName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.mainData["bm_parabirimiId"] = null;
					this.mainData["bm_parabirimiName"] = null;
				}
				else {
					this.mainData["bm_parabirimiId"] = newValue.Value;
					this.mainData["bm_parabirimiName"] = newValue.Name;
				}
			}
		},
	},
	methods: {
		async OnLoad(){
			let loader = this.$loading.show({
				container: this.$refs.smsContainer
			});

			debugger;
			if (this.entityId != null) {
				try {
					debugger;

					let data = await this.crmService.getOtomasyonSatisiById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];

						setTimeout(() => {
							if (this.mainData["bm_satistemsilcisiId"]) {
								this.$refs.entity_bm_satistemsilcisiId.setDefaultData({ "Value": this.mainData["bm_satistemsilcisiId"], "Name": this.mainData["bm_satistemsilcisiName"] });
							}

							if (this.mainData["bm_parabirimiId"]) {
								this.$refs.entity_bm_parabirimi.setDefaultData({ "Value": this.mainData["bm_parabirimiId"], "Name": this.mainData["bm_parabirimiName"] });
							}
						}, 1000);

						if (this.mainData["bm_tarih"]) {
							this.mainData["bm_tarih"] = new Date(this.mainData.bm_tarih);
						}

						if (this.mainData["bm_termintarihi"]) {
							this.mainData["bm_termintarihi"] = new Date(this.mainData.bm_termintarihi);
						}

						if (this.mainData["bm_tamamlanmatarihi"]) {
							this.mainData["bm_tamamlanmatarihi"] = new Date(this.mainData.bm_tamamlanmatarihi);
						}
					}
				} catch (error) {
					console.log(error);
					//this.$router.push('/');
				}
				finally {
					loader.hide();
				}
			}
			else {
				//this.$router.push('/');
				loader.hide();
			}
		},
		async OnSave(kapat) {
			debugger;
			
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				this.v$.$errors.forEach(element => {
					this.$toast.add({ severity: 'error', summary: 'Eksik alanlar var.', detail: element.$message, life: 3500 });
				});
			} else {
				try {
					this.mainData["entityId"] = this.entityId;
					const response = await this.crmService.getOtomasyonSatisiUpdate(this.mainData);
					if (response) {
						if (response.hata == true) {
							this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
						}else {
							this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
							if (window.opener) {
								window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
							}
							
							if (kapat) {
								setTimeout(() => {
									window.close();
								}, 1000);
							}
						}
					}
				} catch (error) {
					this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
				}
			}
		},
		async OnDelete(){
            this.deleteItem(this.ENTITY_NAME, this.entityId);
        },
		OnDevreDisiBirak() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		OnYenidenEtkinlestirItem() {
			const postData = {
				logicalName: this.ENTITY_NAME,
				entiyId: this.entityId,
				stateCode: 0,
				statusCode: 1
			};

			this.yenidenEtkinlestirItem(postData);
		},
		changeSureHafta(event){
			const yeniTarih = new Date(this.mainData["bm_tarih"].getTime() + event * 7 * 24 * 60 * 60 * 1000);
			this.mainData["bm_termintarihi"] = yeniTarih;
			debugger;
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
		changeGecerlilikGunu(event){
			debugger;
			if (event.value) {
				let teklifHazirlanmaTarihi = new Date(this.mainData.bm_teklifhazirlanmatarihi);

				const gecerlilikGunu = event.value;
				let yeniTarih = teklifHazirlanmaTarihi;
				yeniTarih.setDate(teklifHazirlanmaTarihi.getDate() + gecerlilikGunu);
				this.mainData["bm_teklifgecerliliktarihi"] = yeniTarih;
			}
			else {
				this.mainData.bm_teklifgecerliliktarihi = null;
			}
		}
	},
	watch: {
		profileData(val) {
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == "Otomasyon Projesi");
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				bm_satistemsilcisiId: {
					required : helpers.withMessage('Hazırlayan alanı dolu olmalıdır!', required),
				},
				bm_tarih: {
					required : helpers.withMessage('Tarih alanı dolu olmalıdır!', required),
				},
				bm_fiyat: {
					required : helpers.withMessage('Tutar alanı dolu olmalıdır!', required),
				},
				bm_parabirimiId: {
					required : helpers.withMessage('Para Birimi alanı dolu olmalıdır!', required),
				},
				bm_termintarihi: {
					required : helpers.withMessage('Termin Tarihi alanı dolu olmalıdır!', required),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
.stickBar {
	position: sticky;
    top: 6.2rem;
    z-index: 10;
}
</style>
